import categories from "constants/categories";

import React, { useEffect, useState } from "react";

import { Disclosure , RadioGroup } from '@headlessui/react'
import { PencilIcon, PlusIcon, StarIcon } from "@heroicons/react/outline";
import { UserIcon } from "@heroicons/react/solid";
import { POLLFORM_SET_FORM_VALUE_TYPE } from "actions/pollform";
import { PollResponse, createPoll } from "api/poll";
import { IBaseButtonProps } from "components/common/Buttons/BaseButton";
import PrimaryButton from "components/common/Buttons/Primary";
import BaseWithIconButton from "components/common/Buttons/WithIcon";
import { Form } from "components/common/Forms";
import BaseForm from "components/common/Forms/BaseFrom";
import { CreatoIcon } from "components/Icons/CreatoIcon";
import VoteIcon from "components/Icons/VoteIcon";
import { Modal } from "components/Modals";
import Dare from "components/Options/Dare";
import TopSection from "components/Sections/Top";
import dayjs from "dayjs";
import { Link, PageProps, navigate } from "gatsby";
import useLoading from "hooks/common/useLoading";
import usePollFormContext from "hooks/common/usePollFormContext";
import { Method, useRequest } from "hooks/common/useRequest";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AiFillBulb } from "react-icons/ai";
import { ValueType } from "react-select";
import { PollData } from "types/api";
import { getCreatoUser } from "utils/localStorage";

interface IProps extends PageProps<any, any, any> {
}

const NewPoll: React.FunctionComponent<IProps> = ({ location }) => {
  const { pollForm, pollFormDispatch } = usePollFormContext();
  const { isOpen: loading, setLoading } = useLoading();
  const [ isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [ isCongratModalOpen, setIsCongratModalOpen] = useState(false);
  const [ poll, setPoll ] = useState<PollData | undefined>();
  const { data, mutate } = useRequest(poll && poll.teaser ? [poll.teaser, pollForm.teaser] : null, Method.UPLOAD);

  const onInputChange = (option: ValueType<any, false>) => {
    pollFormDispatch({
      type: POLLFORM_SET_FORM_VALUE_TYPE,
      payload: { 
        ...pollForm, 
        [option.name]: option.value
      }
    });
  }
  
  const onPollSubmit: React.MouseEventHandler<HTMLButtonElement> = async () => {
    try {
      setLoading(true);
      const response = await createPoll({
        title: pollForm.title,
        description: "description",
        category: pollForm.category as Category,
        options: pollForm.options.join(","),
        deadline: dayjs().add(pollForm.deadline,"day").toDate(),
      });

      setPoll(response?.data.data);
      // setIsCongratModalOpen(true);
    } catch (error) {
      console.error(error);
      navigate(-1);
    } finally {
      setIsConfirmationModalOpen(false); 
      setLoading(false);
    }
  }

  const onCopy: React.MouseEventHandler<HTMLButtonElement> = async () => {
    try {
      navigate(`/poll/detail/${(poll?.id as string)}`);
    } catch (error) {
      console.error(error);
    } finally {
      setIsCongratModalOpen(false); 
      setLoading(false);
    }
  }

  const onSubmit: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    // const target = e.target as HTMLFormElement;
    try {
    } catch (error) {
      console.error(error);
    } finally {
      setIsConfirmationModalOpen(true); 
    }
  }

  useEffect(() => {
    if (poll?.teaser) {
      mutate();
    }
  }, [poll])

  useEffect(() => {
    if (data) {
      setIsCongratModalOpen(true);
    }
  }, [data])

  return (
    <>
      <Modal.Action 
        open={isConfirmationModalOpen} 
        title="confirm:"
        value="publish"
        onClick={onPollSubmit}
        disabled={loading}
      >
        <p className="text-sm text-gray-500">
          No edit after publishing
        </p> 
      </Modal.Action>
      
      <Modal.Action 
        open={isCongratModalOpen} 
        title="Congratulation!"
      >
        <p className="text-sm text-gray-500">
          Game on!
        </p> 
        <p className="text-sm text-gray-500">
          Spread the words now.
        </p> 
        <div className="mt-5 sm:mt-6">
          <CopyToClipboard text={poll ? `${location.origin}/poll/detail/${(poll?.id as string)}` : location.origin}>
            <button
                type="button"
                className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium capitalize text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:text-sm"
                onClick={onCopy}
              >
                copy link
            </button>
          </CopyToClipboard>
        </div>
      </Modal.Action>
        

      <TopSection
        title={"DareMe"}
        leftComponent={true}
        rightComponent={<AiFillBulb className="text-success-600" />}
      />
      <BaseForm className="max-w-2xl mx-auto px-4 py-4 sm:px-6 lg:max-w-7xl lg:px-8 lg:py-8 lg:mb-9 lg:rounded-lg lg:shadow-lg">
        <div className="lg:grid lg:grid-cols-12 lg:auto-rows-min lg:gap-x-8">
          <div className="lg:col-start-8 lg:col-span-5 lg:row-span-1">
            <Disclosure as="div" className="hidden lg:flex flex-col gap-2 items-start">
              <div className="flex-grow flex flex-col gap-2">
                <div className="flex justify-between">
                  <h5 className="text-secondary-500">
                    {`${pollForm.deadline} Day${pollForm.deadline > 1 ? 's' : ''}`}
                  </h5>
                  <div className="flex">
                  <span className="inline-flex gap-x-2 text-black">
                    <CreatoIcon className="ml-2 h-5 w-5 fill-black" color="" aria-hidden="true"/>
                      0
                    </span>
                    <span className="inline-flex gap-x-2 text-black">
                      <UserIcon className="ml-2 h-5 w-5 fill-black" aria-hidden="true"/>
                      0
                    </span>
                  </div>
                </div>
                <h1 className="text-black">{pollForm.title}</h1>
                <div className="flex justify-between">
                  <Form.Select
                    name="category"
                    value={pollForm.category}
                    selected={pollForm.category.length > 0}
                    options={categories}
                    onChange={onInputChange}
                  />
                </div>
              </div>
            </Disclosure>
          </div>

          {/* Image gallery */}
          <div className="mt-8 lg:mt-0 lg:col-start-1 lg:col-span-7 lg:row-start-1 lg:row-span-3">
            <h2 className="sr-only">Teaser</h2>

            <div className="relative grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-1 lg:gap-8">
              {/* <img
                src={image.length ? image : "https://tailwindui.com/img/ecommerce-images/product-page-01-featured-product-shot.jpg"}
                alt={"teaser"}
                className={"lg:col-span-2 lg:row-span-2 rounded-lg"}
              /> */}
              <div className="lg:col-span-2 lg:row-span-2 rounded-lg">
                <img
                  src={pollForm?.image ? URL.createObjectURL(pollForm.image) : "https://tailwindui.com/img/ecommerce-images/product-page-01-featured-product-shot.jpg"}
                  alt="Wooden shelf with gray and olive drab green baseball caps, next to wooden clothes hanger with sweaters."
                  className={"lg:col-span-2 lg:row-span-2 rounded-lg"}
                />
                <div
                  aria-hidden="true"
                  className="bg-gradient-to-b from-black to-transparent opacity-50 sm:absolute sm:inset-0 lg:hidden"
                />
              </div>
              
              <div className="w-full h-full px-4 py-4 absolute inset-0 flex flex-col items-stretch lg:justify-end">
                <div className="flex-1 flex flex-col lg:hidden">
                  <div className="flex-grow flex flex-col gap-2">
                    <div className="flex justify-between">
                      <h5 className="text-secondary-500">
                        {`${pollForm.deadline} Day${pollForm.deadline > 1 ? 's' : ''}`}
                      </h5>
                      <div className="flex">
                      <span className="inline-flex gap-x-2 text-shades-0">
                        <CreatoIcon className="ml-2 h-5 w-5 fill-shades-0" color="" aria-hidden="true"/>
                          0
                        </span>
                        <span className="inline-flex gap-x-2 text-shades-0">
                          <UserIcon className="ml-2 h-5 w-5 fill-shades-0" aria-hidden="true"/>
                          0
                        </span>
                      </div>
                    </div>
                    <h1 className="text-shades-0">{pollForm.title}</h1>
                    <div className="flex justify-between">
                      <Form.Select
                        name="category"
                        value={pollForm.category}
                        selected={pollForm.category.length > 0}
                        options={categories}
                        onChange={onInputChange}
                      />
                    </div>
                  </div>
                </div>
                <PrimaryButton
                  className="btn-rounded w-full lg:hidden"
                >
                  Dare
                </PrimaryButton>
              </div>
            </div>
          </div>

          <div className="hidden lg:block lg:col-span-5 lg:row-span-1">
            <div className="flex flex-col items-center">
              <PrimaryButton
                  className="btn-rounded w-full"
                >
                  Dare
              </PrimaryButton>
              <span>or</span>
            </div>
          </div>

          <div className="lg:col-span-5 lg:row-span-1">
            <div className="h-full flex flex-col">
              {/* Options */}
              <div className="flex-grow">
                <Link to={`option/1?value=${pollForm.options[0]}`}>
                  <Dare.Default
                    id="option-1"
                    name="option-1"
                    value={pollForm.options[0]}
                    username={getCreatoUser().username || 'owner'}
                    label={
                      pollForm.options[0].length > 0
                        ? `${pollForm.options[0]}`
                        : "Dare Option"
                    }
                    className="mt-1 border-none"
                  >
                    <VoteIcon className="fill-shades-0"/>
                  </Dare.Default>
                </Link>
                <Link to={`option/2?value=${pollForm.options[1]}`}>
                  <Dare.Default
                    id="option-2"
                    name="option-2"
                    value={pollForm.options[1]}
                    label={
                      pollForm.options[1].length
                        ? `${pollForm.options[1]}`
                        : "Dare Option"
                    }
                    username={getCreatoUser().username || 'ownwer'}
                    className="mt-1 border-none"
                  >
                    <VoteIcon className="fill-shades-0"/>
                  </Dare.Default>
                </Link>
              </div>

              <PrimaryButton type="button" onClick={onSubmit} className="w-full mt-4 px-3 py-2" disabled={loading || Object.values(pollForm).filter(value => value?.length === 0).length > 0}>
                Publish
              </PrimaryButton>
            </div>
          </div>
        </div>
      </BaseForm>
    </>
  )
};

export default NewPoll;

interface IFieldButtonProps extends IBaseButtonProps {
  filled: boolean
}

const FieldButton: React.FunctionComponent<IFieldButtonProps> = ({ filled, children, ...props }) => {
  return (
    <BaseWithIconButton className={`btn-pill ${filled ? "btn-primary btn-fill": "btn-primary btn-outline"}`} {...props}>
      {filled ? <PencilIcon className="w-4 h-4" aria-hidden="true" /> : <PlusIcon className="w-4 h-4" aria-hidden="true"/>}
    </BaseWithIconButton>
  )
}
