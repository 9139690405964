import React, { useEffect, useState } from "react";

import PrimaryButton from "components/common/Buttons/Primary";

import { BaseModal, IBaseModelProps } from "./Base";


export interface IActionModalProps extends IBaseModelProps, React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
}

const ActionModal: React.FunctionComponent<IActionModalProps> = ({
  open: isOpen = false,
  title,
  onClick: onNewClick,
  children,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  
  const onClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    onNewClick && onNewClick(e);
    setOpen(false);
  }

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen])

  return (
    <BaseModal open={open} title={title}>
      {children}
      {
        onNewClick && 
          <div className="mt-5 sm:mt-6">
            <button
                type="button"
                className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium capitalize text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:text-sm"
                onClick={onClick}
              >
                {props.value}
            </button>
          </div>
      }
    </BaseModal>
  );
};

export default ActionModal;
