import useSWR, { Fetcher, Key } from 'swr'
import instance from "utils/axios"

enum Method {
  POST = "POST",
  UPLOAD = "UPLOAD",
  GET = "GET",
  PUT = "PUT",
  DELETE = "DELETE",
}

type Fetchers = Record<Method, Fetcher>;

const fetchers: Fetchers = {
  [Method.POST]: async (url, data) => await instance.post(url, data).then(response => response.data),
  [Method.UPLOAD]: async (url, data) => await instance.post(url, data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).then(response => response.data),
  [Method.GET]: async (url, data) => await instance.get(url, data as any).then(response => response.data),
  [Method.PUT]: async (url, data) => await instance.put(url, data).then(response => response.data),
  [Method.DELETE]: async (url, data) => await instance.delete(url, data as any).then(response => response.data),
}

const useRequest = (key: Key, method: keyof typeof Method) => {
  const { data, error, ...swr} = useSWR(key, fetchers[method]);

  return {
    data,
    error,
    ...swr,
  }
}

export { useRequest, Method }